.edit-lexicon {
  display: block;

  > .menu {
    float: left;
    margin-right: 10px;
  }

  > .segment {
    overflow: hidden;
  }
}

.enote {
  .query-response-editor {
    display: block;

    .button.accept {
      min-width: 40px;
      float: left;
      height: 288px;
    }

    .response-editor {
      position: static;
      float: right;
      width: 47.5%;

      .response-tabs {
        display: block;
        position: static;

        > .ui.tab {
          height: 251px;

          &.editing {
            height: 216px;
          }
        }
      }
    }
  }
}
